<template>
  <div class="popup_overlay px-4">
  <div class="custom_dialog rounded-xl" id="itemdialogs" style="width: 600px;max-height: 85%;">
    <div class="relative bg-white">
      <div class="bg-primary flex justify-between p-3 sticky top-0">
        <div class=" font-medium text-white heading-3">
          {{jobCategoryId > 0 ? 'Update Job Category' : 'Add New Job Category'}}
        </div>
        <div v-if="jobCategoryId > 0">
          <div class=" text-error heading-5 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="callJobTypeListAPI()"> 
            <i class="fa-solid fa-trash"></i>
          </div>
        </div>
      </div>
      <div style="max-height: 60vh" class="overflow-auto">
        <div class="p-4">
         <div class="mb-4">
              <TextField
              :inputId="'34324testInput'"
              :textMaxlength="50"
              :showcharLimit="true"
              :inputext="saveJobCateData.categoryName"
              :placholderText="`Job Category Name`"
              :lableText="'Job Category Name'"
              :autoFocus="true"
              @inputChangeAction="(data) => saveJobCateData.categoryName = data"  />
          </div>
        </div>
      </div>
      <div class=" sticky -bottom-1 bg-white w-full flex justify-end pr-3 pt-3">
        <div class="text-rigth flex gap-2 mb-3">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="discard()"/>
          <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" :disabled="saveJobCateData.categoryName.trim() !== '' ? false : true" @buttonAction="SaveJobCategoryDetail()"/>
        </div>
      </div>
    </div>
  </div>
  <div v-if="removeConfirm" class="popup_overlay px-4">
    <div class="p-4 cust_card bg-white" style="width: 600px;max-height: 85%; overflow: visible;">
      <div v-if="categoryLinked" class="mb-4">
        <p class="pa-0 ma-0 font-bold heading-3">This category is linked to jobtype</p>
        <p class="pa-0 ma-0 heading-5">Please select the alternate category.</p>
        </div>
      <div class="relative">
        <div>
          <div v-if="categoryLinked" class="mb-4">
                <Dropdown
                  :disabled="false"
                  :inputext="searchForCategory" 
                  :inputId="'select_job_category'"
                  :lableText="'Select Job Category'"
                  :placholderText="`Select Job Category`"
                  :autoFocus="false"
                  :showArrow="true"
                  :setReadOnly="false"
                  :searchCallApi="true"
                  :showPlus="false"
                  :keyName="'categoryName'"
                  :listId="'jobCategoryId'"
                  :items="jobCategories"
                  @selectNewForVal="selectedCategory"
                  />
          </div>
         
        </div>
         <div class="pa-0 mb-4">
            <p class="pa-0 ma-0 heading-5 font-bold">Once deleted cannot undone.</p>
            <div class="flex">
              <p class="pa-0 ma-0 heading-5 pr-2 text-error">Are you sure delete category?*</p>
              <CheckBoxInput
                :checkValue="deleteJobTypeCheckBox"
                :label="deleteJobTypeCheckBox ? 'Yes' : 'No'"
                @setIsActive="deleteJobTypeCheckBox = !deleteJobTypeCheckBox"  />
              </div>
          </div>
        <div class=" sticky -bottom-1 bg-white w-full flex justify-end">
          <div class="text-rigth flex gap-2">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="removeConfirm = false"/>
            <Button v-if="categoryLinked" :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Ok'" :disabled="alternerJobCategoryId > 0 && deleteJobTypeCheckBox === true ? false : true" @buttonAction="deleteJobCatagoryApi()"/>
            <Button v-else :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Ok'" :disabled="deleteJobTypeCheckBox === true ? false : true" @buttonAction="deleteJobCatagoryApi()"/>
          </div>
        </div>
      </div>
  </div>
</div>
</div>
</template>
<script>
import CheckBoxInput from '@/View/components/checkBoxInput.vue'
import Dropdown from '@/View/components/dropdown.vue'
import Button from '@/View/components/globalButton.vue'
import TextField from '@/View/components/textfield.vue'
import MyJobApp from '@/api/MyJobApp.js'
export default {
props: ['jobCategoryId', 'JCDetail'],
components: {
  CheckBoxInput,
  Button,
  TextField,
  Dropdown,
},
data () {
  return {
    categoryLinked: false,
    deleteJobTypeCheckBox: false,
    removeConfirm: false,
    saveJobCateData: {
      jobCategoryId:0,
      categoryName:'',
      displayOrder:0,
      isActive:true,
      organizationDetailId:0
    },
    jobCategories: [],
    searchForCategory: '',
    alternerJobCategoryId: 0
  }
},
 created() {
},
mounted () {
  if (this.jobCategoryId > 0) {
    if (this.JCDetail !== null) {
      this.saveJobCateData.jobCategoryId = this.JCDetail.jobCategoryId
      this.saveJobCateData.categoryName = this.JCDetail.categoryName
      this.saveJobCateData.displayOrder = this.JCDetail.displayOrder
      this.saveJobCateData.isActive = this.JCDetail.isActive
      this.saveJobCateData.organizationDetailId = this.JCDetail.organizationDetailId
    }
  }
  document.body.style = 'overflow: hidden;'
},
watch: {},
methods: {
  callJobTypeListAPI () {
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
    this.categoryLinked = false
      MyJobApp.GetJobTypeList(
          response => {
            if (response.Data !== null) {
              response.Data.forEach(e=> {
                if (e.jobCategoryId === this.jobCategoryId) {
                  this.categoryLinked = true
                }
              })
            }
            this.getJobCategoryList()
            console.log('jobTypeList', this.jobTypeList)
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
  getJobCategoryList () {
    this.removeConfirm = true
    this.jobCategories = []
    MyJobApp.JobCategoryList(
      response => {
        if (response.Data !== null) {
          response.Data.forEach(element => {
            if (element.jobCategoryId !== this.jobCategoryId)
            this.jobCategories.push(element)
          })
        } else {
          this.removeConfirm = false
        }
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      },
      () => {
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      }
    )
  },
  selectedCategory (data) {
    if (data !== null) {
      console.log('select note cate data', data)
      this.searchForCategory = data.categoryName
      this.alternerJobCategoryId = data.jobCategoryId
    } else {
      this.searchForCategory = ''
      this.alternerJobCategoryId = 0
    }
  },
  discard () {
      document.body.style = 'overflow: visible;'
    this.$root.$emit('addNewJobCategoryComponennt')
  },
  SaveJobCategoryDetail () {
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.JobCategorySave(
      this.saveJobCateData,
      response => {
        this.$root.$emit('addNewJobCategoryComponennt', true)
        this.$store.dispatch('SetLoader', {status: false, message: ''})
        this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
      },
      () => {
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      }
     )
  },
  deleteJobCatagoryApi () {
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.DeleteJobCatagory(
      this.jobCategoryId,
      this.alternerJobCategoryId,
      response => {
        console.log('response', response);
        this.removeConfirm = false
        this.$root.$emit('addNewJobCategoryComponennt', true)
        this.$store.dispatch('SetLoader', {status: false, message: ''})
        this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
      },
      () => {
        this.removeConfirm = false
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      }
     )
  }
},
beforeDestroy () {
  this.$root.$off('confirmBoxHandler')
}
}
</script>
<style scoped>

</style>